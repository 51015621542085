<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 200px' }">
      <a-form-item>
        <a-input v-model="form.orderNo" placeholder="订单编号" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table :data-source="list" :rowKey="record => record.id" bordered :columns="columns" :pagination="pagination">
      <span slot="action" slot-scope="voucher">
        <a @click="handleViewOrder(voucher.orderNo)">订单详情</a>
        <span v-if="voucher.status === 'waitAudit'">
          <a-divider type="vertical" />
          <a @click="$refs.dialog.setForm(voucher);$refs.dialog.isShow = true">通过</a>
          <a-divider type="vertical" />
          <a @click="$refs.dialog.setForm(voucher);$refs.dialog.isShow = true">驳回</a>
        </span>
      </span>
      <span slot="payment" slot-scope="voucher">
        <div>
          <a-row>
            <a-col :span="6"><span color="red">{{voucher.payment}}</span></a-col>
            <a-col :span="18">
              <a  @click="showImageModal(voucher.voucherUrl)">
              <a-avatar shape="square" style="width:80px;height:80px;"
                    :src="voucher.voucherUrl" />
              </a>
            </a-col>
          </a-row>
        </div>
      </span>
      <span slot="memo" slot-scope="voucher">
        <a-row>
          <a-col :span="24">{{voucher.memo}}</a-col>
          <a-col :span="24" style="margin-top: 10px">
            <a-tag color="#108ee9" v-if="voucher.status === 'waitAudit'">待审核</a-tag>
            <a-tag color="#87d068" v-else-if="voucher.status === 'success'">审核通过</a-tag>
            <a-tag color="#f50" v-else>已驳回</a-tag>
          </a-col>
        </a-row>
      </span>
    </a-table>
    <a-modal :footer="null" :visible="imageModal.visible" @ok="closeImageModal" @cancel="closeImageModal" >
      <img style="width:100%" :src="imageModal.voucherImage"/>
    </a-modal>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      isQueryLoading: false,
      imageModal: {
        visible: false,
        voucherImage: ''
      },
      form: {
        orderNo: '',
        statusList: 'success,fail'
      },
      columns: [
        {
          title: '订单编号',
          width: 150,
          ellipsis: true,
          dataIndex: 'orderNo',
          key: 'orderNo'
        },
        { title: '凭证信息', width: 320, scopedSlots: { customRender: 'payment' } },
        { title: '审核说明', scopedSlots: { customRender: 'memo' } },
        {
          title: '时间',
          width: 180,
          ellipsis: true,
          dataIndex: 'deptName',
          key: 'deptName'
        },
        { title: '操作', key: 'operation', fixed: 'right', width: 230, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    // 重置表单
    resetForm (formName) {
      this.form.orderNo = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryPayVoucher',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    showImageModal (image) {
      this.imageModal.visible = true
      this.imageModal.voucherImage = image
    },
    closeImageModal (e) {
      this.imageModal.visible = false
      this.imageModal.voucherImage = ''
    },
    handleViewOrder (orderNo) {
      console.log('查看详情：', orderNo)
      this.$router.push({
        path: '/order/orderDetail',
        query: { orderNo: orderNo }
      })
    }
  }
}
</script>

<style lang="scss" module>
  .container {
    .form__ct {
      padding: 10px 0;
    }
    .form__items {
      padding-top: 6px;
    }
  }
</style>
